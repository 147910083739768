// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  defaultUrl: 'http://217.196.60.125:38080',
  apiUrl: 'http://217.196.60.125:38080/licweb-api',
  tokenWhitelistedDomains: [ new RegExp('217.196.60.125:38080') ],
  // defaultUrl: 'http://192.168.0.100:38080',
  // apiUrl: 'http://192.168.0.100:38080/licweb-api',
  // tokenWhitelistedDomains: [ new RegExp('192.168.0.100:38080') ],
  tokenBlacklistedRoutes: [ new RegExp('\/auth\/login')]
};
