import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import 'rxjs/add/operator/toPromise';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../environments/environment';

@Injectable()
export class LoginService {

  oauthTokenUrl: string;
  refreshTokenUrl: string;
  jwtPayload: any;

  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService) {
      this.oauthTokenUrl = `${environment.apiUrl}/auth/login`;
      this.refreshTokenUrl = `${environment.apiUrl}/auth/refresh-token`;
      this.carregarToken();
    }

  login(login: string, senha: string): Promise<void> {
    const b64 = btoa(`${login}:${senha}`); 
    let auth = `Basic ${b64}`;
    const headers = new HttpHeaders()
      .append('Authorization', auth);    

    return this.http.post<any>(this.oauthTokenUrl, "", { headers })
      .toPromise()
      .then(response => {
        this.armazenarToken(response.token);
      })
      .catch(response => {        
        if (response.status === 400) {
          if (response.error === 'invalid_grant') {
            return Promise.reject(response.error_description);
          }
        }
        return Promise.reject(response);
      });
  }

  obterNovoAccessToken(): Promise<void> {    
    const tokenJWT = localStorage.getItem('token');
    if (tokenJWT) {
      const headers = new HttpHeaders()
        .append('Content-Type', 'application/json');      
  
      const body = {
        'token': tokenJWT
      };
      return this.http.post<any>(this.refreshTokenUrl, body, { headers })
        .toPromise()
        .then(response => {
          this.armazenarToken(response.token);
          return Promise.resolve(null);
        })
        .catch(response => {
          return Promise.resolve(null);
        });
    }

  }

  limparAccessToken() {
    localStorage.removeItem('token');
    this.jwtPayload = null;
  }

  isAccessTokenInvalido() {
    const token = localStorage.getItem('token');    
    return !token || this.jwtHelper.isTokenExpired(token);
  }

  temQualquerPermissao(roles) {    
    for (const role of roles) {
      if (this.temPermissao(role)) {
        return true;
      }
    }
    return false;
  }

  temPermissao(permissao: string) {
    let permissaoValida = false;
    if (this.jwtPayload && this.jwtPayload.authorities.filter(authoritie => authoritie == permissao)) {
      permissaoValida = true;
    }    
    return permissaoValida;
  }

  private armazenarToken(token: string) {
    this.jwtPayload = this.jwtHelper.decodeToken(token);
    localStorage.setItem('token', token);
  }

  private carregarToken() {
    const token = localStorage.getItem('token');    
    if (token != null && token != 'undefined' && token.length > 0) {
      this.armazenarToken(token);
    }
  }
}
