import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { LoginComponent } from './views/seguranca/login.component';
import { RegisterComponent } from './views/register/register.component';
import { P401Component } from './views/error/401.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '401',
    component: P401Component,
    data: {
      title: 'Page 401'
    }
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: ''
    },
    children: [
      {
        path: 'principal',
        loadChildren: './views/principal/principal.module#PrincipalModule'
      },
      {
        path: 'pessoas',
        loadChildren: './views/pessoas/pessoas.module#PessoasModule'
      },
      {
        path: 'usuarios',
        loadChildren: './views/usuarios/usuarios.module#UsuariosModule'
      },
      {
        path: 'perfis',
        loadChildren: './views/perfis/perfis.module#PerfisModule'
      },
      {
        path: 'licencas',
        loadChildren: './views/licencas/licencas.module#LicencasModule'
      },
      {
        path: 'relatorios',
        loadChildren: './views/relatorios/relatorios.module#RelatoriosModule'
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
