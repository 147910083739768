import { Injectable } from '@angular/core';
import { MoneyHttp } from './money-http';
import { LoginService } from './login.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class LogoutService {

  tokensRenokeUrl: string;

  constructor(
    private http: MoneyHttp,
    private loginService: LoginService
  ) {
    this.tokensRenokeUrl = `${environment.apiUrl}/tokens/revoke`;
  }

  logout() {
    console.log('logout')
    localStorage.removeItem('token');
  //   return this.http.delete(this.tokensRenokeUrl, { withCredentials: true })
  //     .toPromise()
  //     .then(() => {
  //       this.loginService.limparAccessToken();
  //     });
  }
}
