import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, registerLocaleData } from '@angular/common';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastyModule } from 'ng2-toasty';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { RegisterComponent } from './views/register/register.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { FormsModule } from '@angular/forms';
import { LoginService } from './views/seguranca/login.service';
import { ErrorHandlerService } from './views/error/errorhandler.service';
import { PessoaService } from './views/pessoas/pessoa.service';
import { MoneyHttp } from './views/seguranca/money-http';
import { SegurancaModule } from './views/seguranca/seguranca.module';
import { HttpClientModule } from '@angular/common/http';
import { P401Component } from './views/error/401.component';
import localePt from '@angular/common/locales/pt';
import { EnderecoService } from './views/pessoas/endereco.service';

registerLocaleData(localePt);

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    FormsModule,
    ToastyModule.forRoot(),
    HttpClientModule,
    SegurancaModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P401Component,
    P404Component,
    RegisterComponent
  ],
  providers: [
    ErrorHandlerService,
    PessoaService,
    EnderecoService,
    LoginService,
    MoneyHttp,
    JwtHelperService,
    {
      provide: LOCALE_ID, useValue: 'pt'
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
