import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import 'rxjs/add/operator/toPromise';
import * as moment from 'moment';

import { MoneyHttp } from '../seguranca/money-http';
import { Endereco } from '../../shared/models/endereco.model';
import { Contato } from '../../shared/models/contato.model';
import { Pessoa } from '../../shared/models/pessoa.model';
import { environment } from '../../../environments/environment';
import { Search } from '../../shared/models/search.model';
import { PessoaRepresentante } from '../../shared/models/pessoa-representante.model';
import { PessoaLicenca } from '../../shared/models/pessoa-licenca.model';
import { VersaoEmUso } from '../../shared/models/versao-em-uso.model';

@Injectable()
export class PessoaService {

  pessoaUrl: string;

  relatorioUrl: string;

  constructor(private http: MoneyHttp) {
    this.pessoaUrl = `${environment.apiUrl}/pessoas`;
    this.relatorioUrl = `${environment.apiUrl}/relatorios`;
  }

  findByid(id: number): Promise<Pessoa> {
    return this.http.get<Pessoa>(`${this.pessoaUrl}/${id}`)
    .toPromise();
  }

  findRepresentantes(): Promise<any> {
    return this.http.get<any>(`${this.pessoaUrl}/representantes`)
      .toPromise();
  }

  search(search: Search): Promise<any> {
    search.sortField = !search.sortField ? 'nome' : search.sortField;
    return this.http.post<any>(`${this.pessoaUrl}/_search`, search)
      .toPromise()
      .then(response =>  {
        const pessoas = response.content;
        const resultado = {
          pessoas,
          totalRegistros: response.totalElements,
          totalPages: response.totalPages
        };
        return resultado;
      });
  }

  salvar(pessoa: Pessoa): Promise<Pessoa> {
    return this.http.post<Pessoa>(this.pessoaUrl, pessoa)
      .toPromise()
      .then(response => response);
  }

  atualizar(pessoa: Pessoa): Promise<Pessoa> {
    return this.http.put<Pessoa>(`${this.pessoaUrl}/${pessoa.id}`, pessoa)
      .toPromise()
      .then(response => response);
  }

  mudarStatus(id: number, ativo: boolean): Promise<void> {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json');
    return this.http.put<any>(`${this.pessoaUrl}/${id}/ativo`, ativo, { headers })
      .toPromise()
      .then(response =>  response);
  }

  limparCii(id: number): Promise<void> {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json');
    return this.http.put<any>(`${this.pessoaUrl}/${id}/cii`, { headers })
      .toPromise()
      .then(response => response);
  }

  salvarEndereco(endereco: Endereco, id: number): Promise<Endereco> {
    return this.http.post<Endereco>(`${this.pessoaUrl}/${id}/enderecos`, endereco)
      .toPromise()
      .then(response => response)
      .catch(erro => erro);
  }

  atualizarEndereco(endereco: Endereco, id: number): Promise<Endereco> {
    return this.http.put<Endereco>(`${this.pessoaUrl}/${id}/enderecos/${endereco.id}`, endereco)
      .toPromise()
      .then(response => response)
      .catch(erro => erro);
  }

  salvarContato(contato: Contato, id: number): Promise<Contato> {
    return this.http.post<Contato>(`${this.pessoaUrl}/${id}/contatos`, contato)
      .toPromise()
      .then(response => response)
      .catch(erro => erro);
  }

  atualizarContato(contato: Contato, id: number): Promise<Contato> {
    return this.http.put<Contato>(`${this.pessoaUrl}/${id}/contatos/${contato.id}`, contato)
      .toPromise()
      .then(response => response)
      .catch(erro => erro);
  }

  relatorioPorCidade(cidadeId: number, filtro: string) {
    return this.http.get(`${this.pessoaUrl}/relatorios/por-cidade?cidadeId=${cidadeId}&exibicao=${filtro}`,
    {headers: '', responseType: 'blob'})
      .toPromise()
      .then(response => response);
  }

  relatorioPorDataCadastro(dataInicial:string, dataFinal:string) {    
    return this.http.get(`${this.pessoaUrl}/relatorios/por-data-cadastro?dataInicial=${dataInicial}&dataFinal=${dataFinal}`,
    {headers: '', responseType: 'blob'})
    .toPromise()
    .then(response => response);
  }

  relatorioPorRepresentante(pessoaRepresentante: PessoaRepresentante) {
    return this.http.post<PessoaRepresentante>(`${this.pessoaUrl}/relatorios/por-cidade-representante`, pessoaRepresentante,
    {headers: '', responseType: 'blob'} )
    .toPromise()
    .then(response => response);
  }

  relatorioPorLicenca(pessoaLicenca: PessoaLicenca) {
    return this.http.post<PessoaLicenca>(`${this.pessoaUrl}/relatorios/por-licenca`, pessoaLicenca,
    {headers: '', responseType: 'blob'} )
    .toPromise()
    .then(response => response);
  }

  relatorioVersoes(versaoEmuso: VersaoEmUso) {
    return this.http.post<VersaoEmUso>(`${this.relatorioUrl}/versoes`, versaoEmuso,
    {headers: '', responseType: 'blob'} )
    .toPromise()
    .then(response => response);
  }

}