import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';
import { ErrorHandlerService } from '../error/errorhandler.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private loginService: LoginService,
    private router: Router,
    private errorHandler: ErrorHandlerService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      if (this.loginService.isAccessTokenInvalido()) {
        return this.loginService.obterNovoAccessToken()
          .then(() => {
            if (this.loginService.isAccessTokenInvalido()) {
              this.router.navigate(['/login']);
              return false;
            }
            return true;
          });
      } else if (next.data.roles && !this.loginService.temQualquerPermissao(next.data.roles) ) {
        this.errorHandler.handle('Usuário não possui permissão para este acesso');
        return false;
      }
      return true;
  }

}
