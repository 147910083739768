export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: any;
  title?: boolean;
  children?: any;
  variant?: string;
  attributes?: object;
  divider?: boolean;
  class?: string;
}

export const navItems: NavData[] = [
  {
    name: 'Principal',
    url: '/principal',
    icon: 'icon-layers'
  },
  {
    name: 'Cadastros',
    url: '/pessoas',
    icon: 'icon-people',
    children: [
      {
        name: 'Clientes',
        url: '/pessoas/pesquisa',
        icon: 'icon-user'
      }
    ]
  },
  {
    name: 'Licenças',
    url: '/licencas',
    icon: 'icon-docs',
    children: [
      {
        name: 'Planos',
        url: '/licencas/planos',
        icon: 'icon-doc'
      },
      {
        name: 'Token',
        url: '/licencas/token',
        icon: 'icon-lock-open'
      }
    ]
  },
  {
    divider: true
  },
  {
    name: 'Relatórios',
    url: '/relatorios',
    icon: 'icon-film',
    children: [
      {
        name: 'Clientes por Cidade',
        url: '/relatorios/pessoas-por-cidade',
        icon: 'icon-film'
      },
      {
        name: 'Clientes por Data de Cadastro',
        url: '/relatorios/pessoas-por-cadastro',
        icon: 'icon-film'
      },
      {
        name: 'Clientes por Representante',
        url: '/relatorios/pessoas-por-representante',
        icon: 'icon-film'
      },
      {
        name: 'Clientes por Licença',
        url: '/relatorios/pessoas-por-licenca',
        icon: 'icon-film'
      },
      {
        name: 'Versões em uso',
        url: '/relatorios/versoes-em-uso',
        icon: 'icon-film'
      }

    ]
  }
];
