import { Injectable } from '@angular/core';
import { MoneyHttp } from '../seguranca/money-http';
import { environment } from '../../../environments/environment';
import { Endereco } from '../../shared/models/endereco.model';
import { Search } from '../../shared/models/search.model';

@Injectable()
export class EnderecoService {

  enderecoUrl: string;

  cidadeUrl: string

  constructor(private http: MoneyHttp) {
    this.enderecoUrl = `${environment.apiUrl}/enderecos`;
    this.cidadeUrl = `${environment.apiUrl}/cidades`;
  }

  consultaCep(cep: string) {
    return this.http.get<any>(`${this.enderecoUrl}/consulta-cep/${cep}`)
    .toPromise()
    .then(response => response)
    .catch(error =>  {
      const erro = error.error.errors[0];
      return Promise.reject(erro);
    });
  }

  searchCidades(search: Search): Promise<any> {
    search.sortField = !search.sortField ? 'nome' : search.sortField;
    search.pageSize = 10;
    return this.http.post<any>(`${this.cidadeUrl}/_search`, search)
    .toPromise()
    .then(response => {
      const cidades = response.content;
      return cidades;
    });
  }

}
