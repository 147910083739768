import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LoginService } from './login.service';
import { ErrorHandlerService } from '../error/errorhandler.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent {

  constructor(
    private loginService: LoginService,
    private errorHandler: ErrorHandlerService,
    private router: Router
    ) {}

  login(form: NgForm) {
    this.loginService.login(form.value.login, form.value.senha)
    .then(() => {
      this.router.navigate(['/principal']);
    })
    .catch(erro => {
      this.errorHandler.handle(erro.error.error_description);
      form.reset();
    });
  }
}
